/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column name={"3pe5ku15d97"}>
        </Column>


        <Column name={"ff08t3rnd2"}>
        </Column>


        <Column className="bg--center pb--30 pt--30" alt={"ekonomický servis"} name={"15oqj51yz1d"} style={{"marginTop":27.6953125,"backgroundImage":"url(https://cdn.swbpg.com/t/1356/1539738323_s=2000x_.jpg)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"MARISIMA s.r.o."}>
              </Title>

              <Subtitle className="subtitle-box ff--2" content={"<span style=\"font-weight: 700;\">Šetřete svůj čas a energii na své podnikání a administrativu přenechte nám.</span><br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--2" content={"<span style=\"font-weight: bold;\">Klademe důraz na osobní kontakt s klientem a přizpůsobení se jeho individuálních potřeb</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"b4bs22pc50t"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"kompletní účetní služby<br>daňová evidence<br>"}>
              </Title>

              <Text className="text-box" style={{"marginBottom":6}} content={"Poskytujeme klientům veškerý servis v oblasti účetnictví. Komunikujeme s úřady. Zařizujeme vše potřebné ohledně vyplnění jakéhokoliv formuláře. Zpracováváme jak podvojné účetnictví tak daňovou evidenci.&nbsp; Klademe důraz na individuální kontakt s klientem, aby bylo vše přizpůsobeno co nejlépe potřebám klienta.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"mzdy a personalistika"}>
              </Title>

              <Text className="text-box" content={"Zpracováváme pro klienty mzdy. Zajišťujeme potřebné výkazy pro zaměstnance. Zpracováváme roční vyúčtování a další povinné mzdové podklady. Komunikujeme s úřady sociálního pojištění, zdravotního pojištění, úřady práce a jinými státními institucemi ve vazbě na zaměstnance i organizaci. Vedeme personalistiku a zpracováváme veškerá data v souladu s platnými GDPR.<a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">zde</a>."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"7w6gg71hcid"} style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(139, 87, 42, 0.57)"}}>
              
              <Title className="title-box" content={"ceník"}>
              </Title>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: bold;\">Podvojné účetnictví</span>&nbsp;"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: bold;\">Podvojné účetnictví je založeno na počtu zápisů účetního řádku</span>.<span style=\"font-weight: bold;\">&nbsp;Nejsme plátci DPH .</span>"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: 700;\">Ceny se odvíjí od individuálních potřeb klienta v závislosti na konkrétních požadavcích a počtu zápisů účetních řádků.</span><br>"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: 700;\">Stanovené ceny vyjadřují následně pravidelné měsíční platby.</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--style3" content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Pro neplátce DPH<br></span><span style=\"color: rgb(0, 0, 0); font-weight: bold;\">&nbsp;od 1000 Kč&nbsp;</span><br>"}>
              </Button>

              <Button className="btn-box btn-box--style3" content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Pro plátce DPH<br></span><span style=\"font-weight: bold; color: rgb(0, 0, 0);\">od 2000 Kč&nbsp;&nbsp;</span><br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(139, 87, 42, 0.57)"}}>
              
              <Title className="title-box" content={"ceník"}>
              </Title>

              <Text className="text-box text-box--style1 ff--2" content={"<span style=\"font-weight: bold;\">Daňová evidence</span>"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: bold;\">Daňová evidence je založena na zápisech do peněžního deníku a do knihy faktur. Nejsme plátci DPH.</span>"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: 700;\">Ceny se odvíjí od individuálních potřeb klienta v závislosti na jeho konkrétních požadavcích a počtu zápisů v peněžním deníku .</span><br>"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: 700;\">Stanovené ceny vyjadřují následně pravidelné měsíční platby pro plátce DPH.</span><br>"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: 700;\">Cena pro neplátce DPH, podnikající na základě živnostenského oprávnění - OSVČ - , je orientační a je stanovena jako čtvrtletní paušál.</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--style3" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">Pro neplátce DPH&nbsp;</span><br><span style=\"color: rgb(0, 0, 0); font-weight: bold;\">od 1000 Kč&nbsp;</span><br>&nbsp;<br>"}>
              </Button>

              <Button className="btn-box btn-box--style3" content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Pro plátce DPH&nbsp;<br></span><span style=\"font-weight: bold; color: rgb(0, 0, 0);\">od 2000 Kč&nbsp;</span><br>&nbsp;<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(139, 87, 42, 0.57)"}}>
              
              <Title className="title-box" content={"ceník"}>
              </Title>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: bold;\">Mzdy a personalistika</span>"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: bold;\">Cena za zpracování mezd se odvíjí od počtu zaměstnanců v podniku. Nejsme plátci DPH.</span>"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: bold;\">Ceny se odvíjí od individuálních potřeb klienta v závislosti na jeho konkrétních požadavcích a počtu zaměstnanců.</span>"}>
              </Text>

              <Text className="text-box ff--2" content={"<span style=\"font-weight: bold;\">Stanovené ceny vyjadřují následně pravidelné měsíční platby.</span>"}>
              </Text>

              <Button className="btn-box btn-box--style3" content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Uvedené ceny jsou stejné pro plátce i neplátce DPH.<br>Cena za zaměstnance od 250 - 350 Kč.<br>Odhlášky a přihlášky na instituce 200 Kč.<br>Zaměstnanci na DPP od 50 Kč.&nbsp;<br>Evidence do systému a zařazení do personalistiky nového zaměstnance 150 Kč.</span><br><br>"}>
              </Button>

              <Button className="btn-box btn-box--style3" content={"<span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: bold;\">Konzultace a poradenství</span><br><span style=\"font-weight: bold;\">Cena 450 Kč na hodinu. Nejsme plátci DPH.</span><br><span style=\"font-weight: bold;\">Cena je platná i pro zastupování na úřadech a kontrolách.</span><br></span><br><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"hcyjn9l1sf8"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Důležité informace pro rok 2021"}>
              </Title>

              <Text className="text-box" content={"https://www.financnisprava.cz/cs/dane/placeni-dani/jak-spravne-zaplatit-dan/2021/2021-jak-spravne-zaplatit-dan-fu-11135<br>https://www.financnisprava.cz/cs/dane/placeni-dani/bankovni-ucty-financnich-uradu<br>https://www.financnisprava.cz/cs/dane/dane/dan-z-prijmu/informace-stanoviska-a-sdeleni/2021/inf-k-prohlaseni-poplatnika-pro-rok-2021-a-zadost-o-rocni-zuctovani-2020-11190<br>https://www.financnisprava.cz/cs/financni-sprava/media-a-verejnost/tiskove-zpravy/2021/priznani-k-dnv-a-dsl-muzete-bez-rizika-sankci-podat-az-do-1-dubna-11170<br>https://www.mfcr.cz/cs/aktualne/koronavirus-covid-19/2020/kompenzacni-bonus-pro-podnikatele-39804<br>https://www.mpo.cz/cz/rozcestnik/pro-media/tiskove-zpravy/zadosti-do-programu-covid-gastro---uzavrene-provozovny-se-zacnou-prijimat-od-pondeli--mpo-predstavilo-i-specialni-kalkulacku--258942/<br>Žádosti o kompenzační bonusy pro společníky s.r.o. nebo OSVČ:&nbsp;https://ouc.financnisprava.cz/kbv/form/bonus<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"s8m6f67u3m"} style={{"backgroundColor":"rgba(61, 160, 22, 1)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24 title-box--invert" content={"www.marisima.cz"}>
              </Title>

              <Text className="text-box ff--1 fs--16 text-box--invert" style={{"maxWidth":404,"marginTop":0,"marginBottom":1}} content={"<span style=\"font-weight: bold;\">MARISIMA s.r.o.&nbsp;<br>IČO: 062 81 362<br>Sídlo: Nová ul. 2774/54&nbsp;<br>370 01 České Budějovice<br></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--26 title-box--invert" content={"Kontaktní osoba"}>
              </Title>

              <Text className="text-box text-box--center ff--1 w--600 text-box--invert ls--001" style={{"maxWidth":378}} content={"Ing. Simona Zeljković. : jednatelka pro účetní poradenství"}>
              </Text>

              <Text className="text-box text-box--center ff--1 w--600 text-box--invert mt--02" content={"Mario Zeljković: jednatel pro velkoobchod, maloobchod a pohostinskou činnost"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box title-box--right fs--26 w--500 title-box--invert" content={"Kontakt"}>
              </Title>

              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"Telefonní kontakt:"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"+420 777 889 406"}>
              </Text>

              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"E-mail:"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"info@marisima.cz"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}